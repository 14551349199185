import 'slick-carousel';

let reviews = $('.reviews-wrap');

if (reviews.length > 0) {
  reviews.slick({
    adaptiveHeight: true,
    variableWidth: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    edgeFriction: 0,
    centerMode: true,
    centerPadding: '10px',

    prevArrow:
      '<button type="button" class="slider-prev"><img class="arrow" src="/static/img/minified-svg/arrow-gray.svg"></button>',
    nextArrow:
      '<button type="button" class="slider-next"><img class="arrow" src="/static/img/minified-svg/arrow-gray.svg"></button>',
  });
}
