let mainImage = $('.product .product-gallery .main img');

$('.product .product-gallery').on('click', '.slide', function (e) {
  let $this = $(this);
  $this.addClass('-active').siblings().removeClass('-active');
  let src = $this.find('img').attr('src');
  mainImage.attr('src', src);
});

const productGallery = $('section.product .product-gallery');
const slides = productGallery.find('.slides');
const galleryMainPhoto = productGallery.find('.main img');

const sizeEls = $('input.radio[name="size"]');
const materialEls = $('[name="material"]:not(".-disabled")');
const noticePrice = $('.notice p.price-there'),
  noticeWithout = $('.notice p.price-absent'),
  priceEl = $('#price'),
  originalEl = $('#oldPrice'),
  orderForm = $('#orderForm'),
  btnEl = orderForm.find('.btn'),
  formatter = new Intl.NumberFormat('ru-RU');

function updatePriceAndNotice() {
  if (window.prices === undefined) {
    return;
  }
  // construct price key
  const size = sizeEls.filter(':checked').val(),
    material = materialEls.filter(':checked').val(),
    key = `${material}|${size}`;
  if (window.prices[key] === undefined) {
    priceEl.text('Цена по запросу');
    if (originalEl) {
      originalEl.hide();
    }
    btnEl.text('узнать цену');
    noticeWithout.show();
    noticePrice.hide();
  } else {
    priceEl.text(formatter.format(window.prices[key].full) + ' руб.');
    if (
      window.prices[key].discount !== undefined &&
      originalEl !== undefined &&
      originalEl.length > 0
    ) {
      originalEl
        .show()
        .text(formatter.format(window.prices[key].discount) + ' руб.');
    }
    btnEl.text('заказать');
    noticeWithout.hide();
    noticePrice.show();
  }
}
updatePriceAndNotice();

sizeEls.on('change', (evt) => {
  updatePriceAndNotice();
});

materialEls.on('change', function (el) {
  let jthis = $(this);
  if (jthis.hasClass('.-disabled')) return;
  let gal = window.productGalleries[jthis.next().text()];
  if (!Array.isArray(gal) || gal.length === 0) {
    return;
  }
  galleryMainPhoto.attr('src', gal[0].thumb);
  if (slides.children().length === gal.length) {
    slides.children().each((i, one) => {
      one.children[0].src = gal[i].thumb;
    });
  } else {
    // полностью перерисовываем
    let first = true;
    let html = gal.reduce((a, one) => {
      a += '<li class="slide';
      if (first) {
        first = false;
        a += ' -active';
      }
      a += '"><img src="' + one.thumb + '"></li>';
      return a;
    }, '');
    slides[0].innerHTML = html;
  }
  updatePriceAndNotice();
});

let sending = false;
const allForms = $('form');
allForms.on('submit', function (e) {
  e.preventDefault();
  if (sending) {
    return;
  }
  sending = true;
  const $this = $(this);

  let http = new XMLHttpRequest(),
    f = this;
  let contactData = f.contact.value;
  contactData = contactData.toLowerCase();

  const rePhone = /^([+]?\d{1,4}[-\s]?|)([\(]?\d{3}[\)]?)[-\s]?\d{3}[-\s]?\d{2}[-\s]?\d{2}$/;
  const reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // валидация номера телефона
  if (
    contactData.length < 4 &&
    !rePhone.test(contactData) &&
    !reEmail.test(contactData)
  ) {
    f.contact.focus();
    window.alert(
      'Введите корректный номер телефона (+7 9NN NNNNNNN) или email.',
    );
    sending = false;
    return;
  }

  let data = `action=get_request&nonce=${window.callbackHandler.nonce}&title=${window.document.title}&url=${window.location.href}&contact=${contactData}`;

  // TODO: добавить сбор данных памятника -
  // материал
  // размеры
  const size = $("input.radio[name='size']:checked").val();
  if (size && size.length > 0) {
    data += `&size=${size}`;
  }
  const material = $("input.radio[name='material']:checked").val();
  if (material && material.length > 0) {
    data += `&material=${material}`;
  }

  let photos = window.localStorage.getItem('sm-checked');
  if (typeof photos === 'string' && photos.length > 0) {
    try {
      photos = JSON.parse(photos);
      data += '&photos=' + photos.join(',');
    } catch (e) {
      console.error(e);
    }
  }

  http.open('POST', window.callbackHandler.url, true);
  http.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

  http.send(data);
  http.onreadystatechange = function () {
    if (http.readyState == 4 && http.status == 200) {
      if (window.yaCounter21436915) {
        window.yaCounter21436915.reachGoal('FORMSEND');
      }
      window.alert('Ваш запрос отправлен!');
    }
    sending = false;
  };
  http.onerror = function () {
    window.alert(
      'К сожалению, что-то пошло не так и ваш запрос не был отправлен. Позвоните нам, если у вас срочный вопрос.',
    );
    sending = false;
  };
});
