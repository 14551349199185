import 'slick-carousel';

let catalog = $('.catalog-slider');

if (catalog.length > 0) {
  catalog.slick({
    variableWidth: false,
    slidesToShow: 9,
    slidesToScroll: 2,
    dots: false,
    infinite: true,
    edgeFriction: 0,
    prevArrow:
      '<button type="button" class="slider-prev"><img class="arrow" src="/static/img/minified-svg/arrow-gray.svg"></button>',
    nextArrow:
      '<button type="button" class="slider-next"><img class="arrow" src="/static/img/minified-svg/arrow-gray.svg"></button>',
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
}
