import lightgallery from 'lightgallery';
import 'lg-thumbnail';
import 'lg-fullscreen';
// import 'lg-zoom';

$(document).ready(function () {
  $('.album-grid .list').lightGallery();
});

(function () {
  let checkedJson = localStorage.getItem('sm-checked');
  let checked = [];
  if (checkedJson !== null) {
    checked = JSON.parse(checkedJson);
    if (Array.isArray(checked)) {
      checked.forEach((src) => {
        $(`.item[data-src="${src}"]`).addClass('checked');
      });
    }
  }
})();

$('.album-grid .list .favorite').on('click', (e) => {
  e.preventDefault();
  e.stopPropagation();

  let checkedJson = localStorage.getItem('sm-checked');
  let checked = [];
  if (checkedJson !== null) {
    checked = JSON.parse(checkedJson);
    if (!Array.isArray(checked)) {
      checked = [];
    }
  }

  const item = e.target.parentElement.parentElement;
  const $item = $(item);
  $item.toggleClass('checked');
  const src = $item.data('src');
  if ($item.hasClass('checked')) {
    checked.push(src);
  } else {
    checked = checked.filter((one) => one !== src);
  }
  window.localStorage.setItem('sm-checked', JSON.stringify(checked));
  if (window.updateFavorite) {
    setTimeout(() => {
      window.updateFavorite();
    }, 100);
  }
  return false;
});
