'use strict';

/*
    This file can be used as entry point for webpack!
 */

import '../../components/header/header';
import '../../components/hero/hero';
import '../../components/reviews/reviews';
import '../../components/homeCatalog/homeCatalog';
import '../../components/product/product';
import '../../components/productGallery/productGallery';
import '../../components/albumGrid/albumGrid';
import '../../components/favorites/favorites';

import Parallax from 'parallax-js';

if (WOW) {
  var wow = new WOW({
    //
  });
  wow.init();
}

if (window.innerWidth > 800) {
  const scene = document.getElementById('scene');
  if (scene) {
    let parallaxInstance = new Parallax(scene, {
      limitY: 0,
      limitX: 100,
      // frictionX: 0.05,
    });
  }
}
