import 'slick-carousel';
import lightgallery from 'lightgallery';
import 'lg-thumbnail';
import 'lg-fullscreen';
// import 'lg-zoom';

$(document).ready(function () {
  $('.productGallerySlider .list').lightGallery({
    selector: '.slide',
  });

  $('.design-album .list').lightGallery({
    selector: '.thumb',
  });
});

let slickk = $('.slider');

if (slickk.length > 0) {
  slickk.slick({
    variableWidth: true,
    lazyLoad: 'progressive',
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    edgeFriction: 0,

    prevArrow:
      '<button type="button" class="slider-prev"><img class="arrow" src="/static/img/minified-svg/arrow.svg"></button>',
    nextArrow:
      '<button type="button" class="slider-next"><img class="arrow" src="/static/img/minified-svg/arrow.svg"></button>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  });
}
