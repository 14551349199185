//
const favoritesPanel = $('.favorites-panel');
const content = favoritesPanel.children('.content');
const images = favoritesPanel.children('.images');
const orderForm = favoritesPanel.children('.order-photos-form');

window.updateFavorite = function () {
  let checkedJson = window.localStorage.getItem('sm-checked');
  let checked = [];
  if (checkedJson !== null) {
    checked = JSON.parse(checkedJson);
    if (Array.isArray(checked) && checked.length > 0) {
      let htmlString = '';
      checked.forEach((src) => {
        htmlString +=
          '<a class="wrap" href="' +
          src +
          '"><img class="img" src="' +
          src +
          '"></a>';
      });

      content.hide();
      orderForm.show();
      images.css({ opacity: 1 }).html(htmlString).show();
    } else {
      images.hide();
      orderForm.hide();
      content.show();
    }
  }
};

window.updateFavorite();

$('.favorites-panel .reset').on('click', function () {
  window.localStorage.setItem('sm-checked', '[]');
  $('.item[data-src]').removeClass('checked');
  window.updateFavorite();
});

// it went into product.js
// let sending = false;
// orderForm.on('submit', function (e) {
//   e.preventDefault();
//   if (sending) {
//     return;
//   }
//   sending = true;
//   const $this = $(this);

//   let http = new XMLHttpRequest(),
//     f = this;
//   let contactData = f.contact.value;
//   contactData = contactData.toLowerCase();

//   const rePhone = /^([+]?\d{1,4}[-\s]?|)([\(]?\d{3}[\)]?)[-\s]?\d{3}[-\s]?\d{2}[-\s]?\d{2}$/;
//   const reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   // валидация номера телефона
//   if (
//     contactData.length < 4 &&
//     !rePhone.test(contactData) &&
//     !reEmail.test(contactData)
//   ) {
//     f.contact.focus();
//     window.alert(
//       'Введите корректный номер телефона (+7 9NN NNNNNNN) или email.',
//     );
//     sending = false;
//     return;
//   }

//   const data = `action=get_request&nonce=${window.callbackHandler.nonce}&title=${window.document.title}&url=${window.location.href}&contact=${contactData}`;

//   http.open('POST', window.callbackHandler.url, true);
//   http.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

//   http.send(data);
//   http.onreadystatechange = function () {
//     /* if (http.readyState == 4 && http.status == 200) {
//       if (window.yaCounter21436915) {
//         window.yaCounter21436915.reachGoal('FORMSEND');
//       }
//     } */
//     window.alert('Ваш запрос отправлен!');
//     sending = false;
//   };
//   http.onerror = function () {
//     window.alert(
//       'К сожалению, что-то пошло не так и ваш запрос не был отправлен. Позвоните нам, если у вас срочный вопрос.',
//     );
//     sending = false;
//   };
// });
